export default {
  title_text: {
    en: "Congratulations",
    de: "Herzlichen Glückwunsch!",
    ru: "Поздравляю!",
  },
  subtitle_text: {
    en: "Thank you for your participation in the briefing. Now you can download your certificate.",
    de: "Vielen Dank für Ihre Teilnahme an der Einweisung. Sie können jetzt Ihr Zertifikat herunterladen.",
    ru: "Благодарим вас за участие в брифинге. Теперь вы можете загрузить свой сертификат.",
  },
  click_hint: {
    en: "The certificate was also sent to the administration.",
    de: "Das Zertifikat wurde auch an die Administration geschickt.",
    ru: "Сертификат также был отправлен в администрацию.",
  },
  download_button: {
    en: "Download",
    de: "Herunterladen",
    ru: "Скачать",
  },
  logout_button: {
    en: "Logout",
    de: "Abmeldung",
    ru: "Отписаться от рассылки",
  },
};
