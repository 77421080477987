import React from "react";
import { useHistory } from "react-router-dom";
import LogoImage from "../../utils/logo.png";

const Logo = () => {
  const history = useHistory();


  const onClick = () => {
    history.push({
      pathname: "/",
    });
  };
  
  return (
    <div className="logo d-flex justify-content-center">
      <img src={LogoImage} style={{ width: "100%" }} class="img-fluid"></img>
    </div>
  );
};

export default Logo;
