import "./App.css";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Userdata from "./pages/userdata/Userdata";
import Courses from "./pages/courses/Courses";
import Training from "./pages/training/Training";
import Quiz from "./pages/quiz/Quiz";
import TestingUI from "./pages/testingUI/TestingUI";
import Certificate from "./pages/certificate/Certificate";
import MaterialRouter from "./repository/MaterialRouter";
import TrainingPDF from "./pages/training/TrainingPDF";
import Logo from "./utils/sharedComponents/Logo";
import PrivacyStatement from "./pages/privacyStatement/PrivacyStatement";
import Imprint from "./pages/privacyStatement/Imprint";
import useScreenOrientation from "react-hook-screen-orientation";
import logo_top from "./utils/logo_top.png";
import logo from "./utils/logo.png";
import ImprintFooter from "./utils/sharedComponents/ImprintFooter";
import Trainer from "./pages/trainer/Trainer";
import BypassObcc from "./pages/bypassobcc/BypassObcc";
import LandingPage from "./pages/home/LandingPage";

function App() {
  const screenOrientation = useScreenOrientation();
  return (
    <>
      {screenOrientation === "landscape-primary" ? (
        <Logo />
      ) : (
        <nav
          className="navbar d-flex justify-content-center"
          // style={{ padding: 10, backgroundColor: "white", marginBottom: -70 }}
          style={{ padding: 10, backgroundColor: "white",  marginBottom: -50 }}

        >
          {/* <img alt="logo" src={logo_top} height="70" /> */}
          <img alt="logo" src={logo} height="70" />

        </nav>
      )}

      <div className="container">
        <Router>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/userdata" component={Userdata} />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/training" component={Training} />
          <Route exact path="/trainingpdf" component={TrainingPDF} />
          <Route exact path="/quiz" component={Quiz} />
          <Route exact path="/certificate" component={Certificate} />
          <Route exact path="/material" component={MaterialRouter} />
          <Route exact path="/trainer" component={Trainer} />
          <Route exact path="/bypassobcc" component={BypassObcc} />
          <Route exact path="/privacyStatement" component={PrivacyStatement} />
          <Route exact path="/imprint" component={Imprint} />
          <Route exact path="/tui" component={TestingUI} />
          <Route exact path="/grouptraining.php" component={TestingUI} />
        </Router>
      </div>

      <ImprintFooter>
        <a href="/imprint" style={{ color: "white" }}>
          <span id="imprint_button">Impressum</span>
        </a>
        <a href="/privacyStatement" style={{ marginRight: "auto", color: "white" }}>
          <span id="privacy_agree_link">Datenschutzhinweise & Nutzungsbedingungen</span>
        </a>
      </ImprintFooter>
    </>
  );
}

export default App;
